import { useMutation, useQuery } from '@tanstack/react-query';
import { getNotificationsApi, markNotificationsAsReadApi } from '../api/notifications';
import transformEntityTimeToDateObjects from '../helpers/transformEntityTimeToDateObjects';
import { Notification } from '../types/notificationType';
import { compareDesc } from 'date-fns';
import useRestaurant from './useRestaurant';
import { queryClient } from '../services/query';

export default () => {
    const restaurant = useRestaurant();

    const query = useQuery<Notification[]>({
        queryKey: ['notifications', restaurant.id],
        queryFn: getNotificationsApi,
        select: (data) =>
            transformEntityTimeToDateObjects(data).sort((itemA, itemB) => compareDesc(itemA.createdAt, itemB.createdAt)),
        staleTime: 1000 * 60 * 60 * 24, // 24 hours
        refetchOnReconnect: 'always'
    });

    const hasUnread = !!query.data?.some((item) => !item.read);
    const amountOfUnread = query.data?.filter((item) => !item.read).length || 0;

    const mutation = useMutation<Notification[]>({
        mutationFn: markNotificationsAsReadApi,
        retry: 2,
        onSuccess(data) {
            queryClient.setQueryData(['notifications', restaurant.id], data);
        }
    });

    return { ...query, markAllAsRead: mutation.mutate, hasUnread, amountOfUnread };
};
