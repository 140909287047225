import { shouldIgnoreOrderUpdate } from '../../../helpers/order/shouldIgnoreOrderUpdate';
import { showOrderUpdateNotification } from '../../../helpers/order/showOrderUpdateNotification';
import { playSound } from '../../../helpers/playSound';
import queryClient from '../../query/queryClient';
import i18n from '../../../localization/i18n';
import { showSuccessToast } from '../../toaster';
import { OrderModel, RestaurantModel } from '../../../models';
import { OrderData } from '../../../types/orderDataType';
import { RestaurantData } from '../../../types/restaurantDataType';
import { useOrdersStore } from '../../../store/orders';
import { useOrderNotificationsStore } from '../../../store/orderNotifications';
import { updateOrdersInOrdersQuery } from '../../query';
import transformEntityTimeToDateObjects from '../../../helpers/transformEntityTimeToDateObjects';
import batchExecutor from '../../../helpers/batchExecutor';

const addToBatch = batchExecutor<OrderModel>(async (newOrders) => {
    const restaurant = queryClient.getQueryData<RestaurantData>(['restaurant']) as RestaurantData;
    const ordersState = useOrdersStore.getState();

    newOrders.forEach((newOrder) => {
        const orders = queryClient.getQueryData<Map<number, OrderModel>>(['orders', restaurant?.id]) ?? new Map();
        const orderToUpdate = orders.get(newOrder.id);
        const orderWasManuallyUpdated = ordersState.manuallyChangedOrderIds.includes(newOrder.id);

        const needToShowNotification = showOrderUpdateNotification(newOrder, orderToUpdate);

        // Do not notify if order was updated manually previously
        if (!orderWasManuallyUpdated && needToShowNotification && restaurant) {
            showSuccessToast(
                i18n.t('orders.live_orders_messages.main.orderid_has_been_updated', { orderId: newOrder.public_reference }),
                {
                    onShow: () => playSound(restaurant.ui_settings.order_update_sound || 'default'),
                    toastId: typeof document === 'undefined' ? 'order-update' : `order-${newOrder.id}` // Don't stack toasts on mobile
                }
            );
        }

        ordersState.actions.receivedOrderUpdateFromSockets(newOrder.id);

        if (newOrder.is_cancelled) {
            ordersState.actions.orderCancelled(newOrder.public_reference);

            return;
        }

        const courierAdded = newOrder.is_in_kitchen && newOrder.couriers.length > orderToUpdate.couriers.length;
        const orderIsReadyForKitchen = newOrder.is_ready_for_kitchen && !orderToUpdate.is_ready_for_kitchen;

        if (courierAdded || orderIsReadyForKitchen) {
            const notificationsState = useOrderNotificationsStore.getState();
            notificationsState.actions.pushOrderNotification(restaurant.reference, 'PrepareOrder', newOrder);

            return;
        }
    });

    updateOrdersInOrdersQuery(newOrders);
});

export default function orderUpdateLsitener(newOrderData: OrderData) {
    const restaurantData = queryClient.getQueryData<RestaurantData>(['restaurant']);
    const restaurant = restaurantData && new RestaurantModel(restaurantData);

    const newOrder = new OrderModel(transformEntityTimeToDateObjects(newOrderData));
    const orders = queryClient.getQueryData<Map<number, OrderModel>>(['orders', restaurant?.id]) ?? new Map();
    const orderToUpdate = orders.get(newOrderData.id);

    if (orderToUpdate === undefined || restaurant === undefined || shouldIgnoreOrderUpdate(orderToUpdate, newOrder, restaurant)) {
        return;
    }

    addToBatch(newOrder);
}
