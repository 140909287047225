import notificationMessageBuilder from '../../../helpers/notification/notificationMessageBuilder';
import { Notification } from '../../../types/notificationType';
import { markNotificationAsReadApi } from '../../../api/notifications';
import { showInfoToast } from '../../toaster';
import { RestaurantModel } from '../../../models';
import { isProduction } from '../../../helpers/isProduction';
import { updateNotificationInNotificationsQuery } from '../../query';

export default (restaurant: RestaurantModel, notification: Notification): void => {
    const message = notificationMessageBuilder(restaurant.timezone, notification)[0]; // 0: mainText, 1: subText which is not needed here

    updateNotificationInNotificationsQuery(notification);

    const onClose = () =>
        markNotificationAsReadApi(notification.id)
            .then((newNotification) => updateNotificationInNotificationsQuery(newNotification))
            .catch((error) => !isProduction() && console.error(error));

    showInfoToast(message, { onClose });
};
