import { create } from 'zustand';

type ToasterStoreType = {
    enabled: boolean;
    bottomOffset: number;
    actions: {
        disableToaster: () => void;
        enableToaster: () => void;
        setToasterBottomOffset: (value: number) => void;
        resetToasterBottomOffset: () => void;
    };
};

const toasterStore = create<ToasterStoreType>((set) => ({
    enabled: true,
    bottomOffset: 0,
    actions: {
        disableToaster: () => set({ enabled: false }),
        enableToaster: () => set({ enabled: true }),
        setToasterBottomOffset: (value: number) => set({ bottomOffset: value }),
        resetToasterBottomOffset: () => set({ bottomOffset: 0 })
    }
}));

const useToasterIsEnabled = () => toasterStore((state) => state.enabled);
const useToasterBottomOffset = () => toasterStore((state) => state.bottomOffset);
const useToasterStoreActions = () => toasterStore((state) => state.actions);

export { useToasterIsEnabled, useToasterBottomOffset, useToasterStoreActions };
